import { isRef, ref, isReactive, reactive, computed, watchEffect, watch, onUnmounted, type Ref, type App } from "vue";
import { useRequest, type Service } from "vue-request";
import { useUserStore } from "@/store/user";
import { getHomeInfo, getFoodarticleList } from "@/apis/recipe";
import { showLoadingToast, closeToast } from "vant";
import { Haa } from "@haa/fwk-h5/lib/index";
import router from "@/router";
import { useRoute } from "vue-router";
import { SPACE_MAP } from "@/utils/constant";
import { useControlStore } from "@/store/control";
import { storeToRefs } from "pinia";
import bus from "@haa/fwk-h5/lib/bus";
import { pathNames } from "@/router/routes";

/** 初始化用户信息hook */
export const useInit = (app: App<Element>) => {
  const { init } = useUserStore();
  // 初始化uuid与query
  init();

  /** 初始化sdk */
  let cfg = {
    queryMap: {
      channel: "channel",
      subchannel: "subchannel",
      key: "key"
    },
    router: router,
    home: [pathNames.home, pathNames.tabRecipe, pathNames.tabBaike, pathNames.tabTopic],
    app: app,
    spaceMap: SPACE_MAP,
    deepStart: process.env.VUE_APP_START == "guide" ? 1 : 2
  };

  Haa.init(cfg);
};

/** 首页信息hook */
export const useHome = (tabList: Array<TabBarItem>, active: Ref<number> | number = 0) => {
  const _active = isRef(active) ? active : ref(active);

  const _currTab = computed(() => {
    return tabList[_active.value];
  });

  return {
    tabList: tabList,
    active: _active,
    currTab: _currTab
  };
};

/** 首页tabContent1 hook */
export const useHomeTabMain = (flag: boolean = true) => {
  const { data, loading } = useRequest(getHomeInfo);
  loading.value = true;

  const foodArticle = computed<IRecipe | undefined>(() => {
    return data.value?.food_article.data[0];
  });

  const foodBaike = computed<IRecipe | undefined>(() => {
    return data.value?.food_baike.data[0];
  });

  const foodSolar = computed<ISolar | undefined>(() => {
    return data.value?.food_solar.data[0];
  });

  watchEffect(() => {
    loading.value ? showLoadingToast({ message: "拼命加载中...", duration: 0 }) : closeToast();
  });

  // 获取美食列表前十条数据 确保列表长度
  const recipeList = ref<IRecipe[]>([]);
  // 控制是否获取列表
  flag &&
    getFoodarticleList({ page: 1, limit: 10 }).then((res) => {
      if (res.data.length) {
        recipeList.value = res.data;
      }
    });

  return {
    foodArticle,
    foodBaike,
    foodSolar,
    loading,
    recipeList
  };
};

/** 通用 加载更多列表 hook
 *
 *
 *
 * requestHandler: 请求的api接口
 * initload: 是否初始加载一次
 * params: 需要而外传入的参数
 */
interface IUseOptions {
  /** 是否初始加载数据 */
  initload?: boolean;
  /** 请求时另外的参数 */
  params?: { [key: string]: any };

  defaultPage?: number;
}
export const useLoadList = <T>(requestHandler: Service<unknown, any>, options: IUseOptions = {}, random?:boolean) => {
  const list = ref<T[]>([]);

  const initload = options.initload ?? true;

  const _params: { [key: string]: any } = ref({
    ...(options.params ?? {})
  });

  const pagination = reactive<Pagination>({
    page: (options?.defaultPage ?? 1) - 1,
    limit: 10,
    total: null
  });

  const _pagination = computed(() => ({
    page: pagination.page + 1,
    limit: pagination.limit,
    // 传入的额外参数
    ..._params.value
  }));

  /** 列表状态 begin */
  const _error = ref(false);
  const _refresh = ref(false);
  // 没有更多
  const finished = computed(() => {
    return pagination.total != null && pagination.total === list.value.length;
  });

  // 是否初始化数据完成，列表中有数据代表初始化已完成
  const isInit = computed(() => {
    return !!list.value.length;
  });
  /** 列表状态 end */

  const { data, loading, runAsync } = useRequest(requestHandler, {
    manual: true
  });

  // 判断是否初始加载数据
  if (initload) {
    // 初始先赋值true 防止vant list组件初始触发 onload事件
    loading.value = true;
    runAsync({ ..._pagination.value }).catch(() => {
      _error.value = true;
    });
  }

  watch(
    () => data.value as Pagination & { data: any[] },
    (val) => {
      pagination.total = val?.total || null;
      pagination.page = val?.page;
      if(random && val && val.data) {
        val.data.sort(()=>Math.random() - 0.5)
      }
      list.value.push(...(val?.data || []));
    }
  );

  const setParmas = (params: { [key: string]: any }) => {
    Object.assign(_params.value, params);
  };

  // 请求更多数据
  const onRequestMore = () => {
    if (finished.value) return;
    if (loading.value) return;
    if (_refresh.value) return;
    if (_error.value) return;

    return runAsync({ ..._pagination.value })
      .then(() => {
        _error.value = false;
      })
      .catch(() => {
        _error.value = true;
      });
  };

  // 刷新数据
  const onRefresh = (params: { [key: string]: any }) => {
    if (_refresh.value) return;
    _refresh.value = true;

    // 回到初始值
    pagination.page = 0;
    pagination.limit = 10;
    pagination.total = null;
    list.value = [];

    setParmas(params);

    return runAsync({ ..._pagination.value })
      .then(() => {
        _error.value = false;
      })
      .catch(() => {
        _error.value = true;
      })
      .finally(() => {
        _refresh.value = false;
      });
  };

  return {
    list,
    pagination,
    loading,
    error: _error,
    finished,
    isInit,
    setParmas,
    onRequestMore,
    onRefresh
  };
};

// 返回逻辑处理
export const useBack = () => {
  const route = useRoute();

  const routerBack = () => {
    if (route.query.return == "1" || route.params.first == "1") {
      return router.replace({ path: "/home" });
    }

    router.back();
  };

  return {
    routerBack
  };
};

// 初始化插屏展示
export const useInitInter = () => {
  const visiable: { [key: string]: Ref<boolean> } = {
    HomeDialogInter: ref(false),
    HomeInter: ref(false)
  };

  const { interShown } = storeToRefs(useControlStore());
  const { setInterShown } = useControlStore();

  const { strategy } = Haa.util().useStrategy();

  const initInter = () => {
    if (strategy.status == 0) return;

    // 策略请求失败
    // if (strategy.status == 2) {
    //   visiable.HomeDialogInter.value = true;
    //   return;
    // }

    // 单次启动只展示一次首页插屏
    if (!interShown.value) {
      const isShow = Object.keys(strategy.h5_ad_space_config).some((key) => {
        const flag = Object.keys(visiable).includes(key);

        if (flag) {
          visiable[key].value = true;
        }

        return flag;
      });

      isShow && setInterShown(true);
    }
  };

  initInter();

  bus.on("strategy", initInter);

  onUnmounted(() => {
    bus.off("strategy", initInter);
  });

  return {
    dialogVisiable: visiable.HomeDialogInter,
    interVisiabel: visiable.HomeInter
  };
};
